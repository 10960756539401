html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
::after,
::before {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1>* {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4>* {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-sm-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-sm-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-md-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-md-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-lg-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-lg-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-xl-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-xl-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

html {
  font-size: 14px !important;
  line-height: normal;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  min-height: 100vh;
  margin: 0;
  color: #212225;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

.mainBackground {
  background: #fff;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #212225;
}

address {
  font-style: normal;
}

.external-link {
  font-weight: 600;
  display: inline-block;
  margin-top: 45px;
  padding: 12px 76px;
  cursor: pointer;
  transition: all 0.3s linear;
  border: none;
  border-radius: 20.5px;
  outline: none;
  background-color: #ffe907;
}

.external-link:hover {
  color: #fff;
  background: #2273ff;
}

.gatsby-image-wrapper {
  display: inline-block !important;
}

.gatsby-image-wrapper picture source,
.gatsby-image-wrapper picture img {
  position: relative !important;
  width: unset !important;
  height: unset !important;
}

.gatsby-announcer {
  display: none !important;
}

.Header {
  background-image: url(/public/images/banner-new.jpg);
  min-height: auto;
  background-position: center;
  background-size: cover;
  padding: 78px 0 70px;
  text-align: center;
}

@media (min-width: 576px) {
  .Header {
    min-height: 480px;
    text-align: left;
  }
}

.Header .container .hero-title {
  max-width: 680px;
  margin: 0;
  line-height: 1.21;
  font-size: 2rem;
  font-weight: bold;
}

@media (min-width: 576px) {
  .Header .container .hero-title {
    font-size: 2.714rem;
  }
}

.Header .container .hero-subtitle {
  max-width: 561px;
  font-size: 1rem;
  line-height: 2;
}

@media (min-width: 576px) {
  .Header .container .hero-subtitle {
    font-size: 1.143rem;
  }
}

.HowItWork {
  padding: 66px 0;
  text-align: center;
}

@media (min-width: 576px) {
  .HowItWork {
    padding: 66px 0 132px;
    text-align: left;
  }
}

.HowItWork .list-container {
  margin-top: 46px;
}

.HowItWork .list-container .list-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  text-align: left;
}

@media (min-width: 576px) {
  .HowItWork .list-container .list-item {
    height: 54px;
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .HowItWork .list-container .list-item {
    margin-bottom: 0;
  }
}

.HowItWork .list-container .list-item .number {
  color: #ffe907;
  line-height: 0.58;
  font-size: 4.28rem;
  font-weight: bold;
  margin-right: 21px;
}

@media (min-width: 576px) {
  .HowItWork .list-container .list-item .number {
    font-size: 5.714rem;
  }
}

.HowItWork .list-container .list-item .text {
  line-height: 1.79;
}

@media (min-width: 768px) {
  .HowItWork .list-container .list-item .text {
    align-self: flex-start;
  }
}

.HowItWork .list-container .list-item .text a {
  display: inline-block;
  color: #2272ff;
}

.Demo {
  background: #f9fbfd;
  padding: 40px 0 66px;
}

.Demo .demo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 992px) {
  .Demo .demo-container {
    flex-direction: row;
    align-items: start;
  }
}

.Demo .demo-container .demo-info {
  max-width: 100%;
  text-align: center;
  margin-top: 25px;
}

@media (min-width: 768px) {
  .Demo .demo-container .demo-info {
    max-width: 660px;
  }
}

@media (min-width: 992px) {
  .Demo .demo-container .demo-info {
    text-align: left;
    margin-left: 35px;
    margin-top: 0;
  }
}

.Demo .demo-container .demo-info .demo-text {
  max-width: 574px;
  line-height: 1.79;
  margin: 13px auto 0;
}

@media (min-width: 992px) {
  .Demo .demo-container .demo-info .demo-text {
    margin: 13px auto 0 0;
  }
}

.Demo .demo-container a {
  margin: 25px auto 0;
}

@media (min-width: 992px) {
  .Demo .demo-container a {
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 22px;
  }
}

.Payments {
  padding: 84px 0 95px;
}

.Payments .payment-container {
  display: flex;
  margin-top: 44px;
  justify-content: center;
}

@media (min-width: 576px) {
  .Payments .payment-container {
    justify-content: left;
  }
}

.Payments .payment-container .payment-item {
  align-self: center;
  margin-bottom: -10px;
}

.Payments .payment-container img{
  max-width: 100%;
}
.Cms {
  background: #f9fbfd;
  padding: 86px 0 80px;
}

.Cms .cms-container {
  text-align: center;
  margin-top: 60px;
}

.Cms .cms-container .payment-item {
  height: 48px;
  margin-bottom: 25px;
}

.Cms .cms-container .payment-item:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .Cms .cms-container .payment-item {
    margin-bottom: 30px;
  }
}

.Instruments {
  padding: 86px 0 80px;
}

.Instruments .instrument-container {
  margin-top: 64px;
}

.Instruments .instrument-container .instrument-item {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media (min-width: 576px) {
  .Instruments .instrument-container .instrument-item {
    flex-direction: row;
    align-items: start;
    text-align: left;
  }
}

.Instruments .instrument-container .instrument-item .instrument-list {
  max-width: 100%;
  margin-left: 0;
}

@media (min-width: 576px) {
  .Instruments .instrument-container .instrument-item .instrument-list {
    margin-left: 15px;
  }
}

.Instruments .instrument-container .instrument-item .instrument-list .title,
.Instruments .instrument-container .instrument-item .instrument-list .subtitle {
  margin: 0;
  line-height: 1.25;
  font-weight: bold;
  font-size: 1.429rem;
}

.Instruments .instrument-container .instrument-item .instrument-list .subtitle {
  font-size: 1.143rem;
}

.Instruments .instrument-container .instrument-item .instrument-list .description {
  font-size: 0.9286rem;
  line-height: 1.69;
}

.Commission {
  background: #d9e9f6;
  text-align: center;
  padding: 65px 0 36px;
}

@media (min-width: 768px) {
  .Commission {
    text-align: right;
  }
}

.Commission .commission-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

@media (min-width: 768px) {
  .Commission .commission-container {
    text-align: left;
  }
}

.Commission .commission-container .all {
  line-height: 1.28;
  font-size: 2.571rem;
}

.Commission .commission-container .percent {
  line-height: 0.79;
  font-weight: bold;
  font-size: 4.143rem;
  margin: 15px 0 6px;
}

@media (min-width: 768px) {
  .Commission .commission-container .percent {
    margin: 0;
  }
}

.Commission .commission-container .description {
  line-height: 1.79;
  padding: 0 15px;
}

@media (min-width: 576px) {
  .Commission .commission-container .description {
    padding: 0;
  }
}

.Commission a {
  margin-top: 6px;
}

@media (min-width: 768px) {
  .Commission a {
    margin-top: 20px;
  }
}

.partners {
  padding: 88px 0 114px;
  &__row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 576px) {
      flex-direction: column;
      justify-content: center;
    }
    & > a {
      flex-shrink: 0;
      flex-basis: 25%;
      margin-top: 52px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}


.Security {
  background: #f9fbfd;
  padding: 82px 0 132px;
}

.Security .security-container {
  align-items: center;
  margin-top: 25px;
  text-align: center;
}

@media (min-width: 576px) {
  .Security .security-container {
    text-align: left;
  }
}

.Security .security-container .list-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.Security .security-container .list-items .img-container {
  padding: 0 14px;
  margin-bottom: 25px;
}

@media (max-width: 576px) {
  .Security .security-container .list-items .img-container {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .Security .security-container .list-items .img-container {
    margin-top: 25px;
  }
}

@media (min-width: 1200px) {
  .Security .security-container .list-items .img-container {
    margin-bottom: 0;
  }

  .Security .security-container .list-items .img-container:first-child {
    padding-left: 0;
  }

  .Security .security-container .list-items .img-container:last-child {
    padding-right: 0;
  }
}

.About {
  background: #fff200;
  padding: 58px 0 83px;
  text-align: center;
}

@media (min-width: 768px) {
  .About .bank-logo {
    text-align: right;
  }
}

.About .company {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  .About .company {
    flex-direction: row;
  }
}

.About .company h2 {
  margin: 24px 0;
}

@media (min-width: 768px) {
  .About .company h2 {
    margin: -24px 0 0;
  }
}

.About .company .company-logo {
  margin-left: 0;
}

@media (min-width: 576px) {
  .About .company .company-logo {
    margin-left: 12px;
  }
}

.About .description {
  line-height: 1.79;
  text-align: center;
}

@media (min-width: 576px) {
  .About .description {
    text-align: left;
  }
}

.About .description a {
  color: #2272ff;
  font-weight: bold;
}

.MainConnect {
  padding: 95px 0 74px;
  text-align: center;
  background-color: #f9fbfd;
}

.MainConnect .connect-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) {
  .MainConnect .connect-container {
    flex-direction: row;
    text-align: left;
  }
}

.MainConnect .connect-container .count {
  display: flex;
  justify-content: center;
}

.MainConnect .connect-container .count .connect-count {
  margin-top: 10px;
  width: 44px;
  height: 66px;
  border-radius: 3px;
  background-color: #212225;
  color: #ffe907;
  text-align: center;
  margin-right: 3px;
  font-weight: bold;
  font-size: 3.429rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainConnect .connect-container .connect-group {
  width: 100%;
  text-align: center;
}

@media (min-width: 576px) {
  .MainConnect .connect-container .connect-group {
    margin-left: 34px;
  }
}

@media (min-width: 768px) {
  .MainConnect .connect-container .connect-group {
    text-align: left;
  }
}

.MainConnect .connect-container .connect-group .group-header {
  line-height: 1.55;
  font-weight: bold;
  font-size: 1.571rem;
  max-width: 696px;
  margin-top: 24px;
}

@media (min-width: 576px) {
  .MainConnect .connect-container .connect-group .group-header {
    margin-top: 0;
  }
}

.MainConnect .connect-container .connect-group .group-footer {
  display: block;
  justify-content: space-between;
  margin-top: 24px;
  text-align: center;
}

@media (min-width: 992px) {
  .MainConnect .connect-container .connect-group .group-footer {
    flex-direction: row;
    display: flex;
  }
}

.MainConnect .connect-container .connect-group .group-footer h3 {
  font-size: 1.857rem;
  text-align: center;
}

@media (min-width: 576px) {
  .MainConnect .connect-container .connect-group .group-footer h3 {
    align-self: start;
  }
}

@media (min-width: 992px) {
  .MainConnect .connect-container .connect-group .group-footer a {
    margin-top: 0;
  }
}

.Documentation {
  padding: 63px 0 85px;
}

.Documentation .subtitle {
  margin-bottom: 36px;
  max-width: 690px;
}

.Documentation .list .item {
  display: flex;
  align-items: center;
  color: #2273ff;
  margin-top: 15px;
}

.Documentation .list .item .icon {
  width: 35px;
}

.Documentation .list .item .text {
  color: inherit;
  line-height: 1.56;
  font-weight: bold;
  font-size: 1.143rem;
  margin-left: 15px;
  text-decoration: underline;
  cursor: pointer;
}

.Widget {
  text-align: center;
}

@media (min-width: 576px) {
  .Widget {
    text-align: left;
  }
}

.Widget .top-section {
  padding-top: 66px;
  background: #f9fbfd;
  height: 1120px;
}

@media (min-width: 768px) {
  .Widget .top-section {
    height: 600px;
  }
}

.Widget .top-section .description {
  max-width: 900px;
  line-height: 1.79;
}

.Widget .top-section .widget-container {
  margin-top: 46px;
  justify-content: center;
}

.Widget .top-section .widget-container .card {
  margin: 0;
}

@media (min-width: 768px) {
  .Widget .top-section .widget-container .card {
    max-width: 320px;
  }
}

@media (min-width: 992px) {
  .Widget .top-section .widget-container .card {
    margin: 0 31px;
  }
}

.Widget .top-section .widget-container .card .card-title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2.3;
  margin: 0 0 13px;
}

.Widget .top-section .widget-container .card .card-body {
  text-align: center;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, .09);
  background-color: #fff;
  padding: 26px 0 49px;
}

.Widget .top-section .widget-container .card .card-body .body-title {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1.28;
  color: #c9c9c9;
  margin: 0 0 21px;
}

.Widget .top-section .widget-container .card .card-body .body-description {
  margin: 3px 0 0;
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  color: #8d9099;
}

.Widget .top-section .widget-container .card .card-body .body-description .description-price {
  font-weight: bold;
}

.Widget .top-section .widget-container .card .card-body a {
  margin-top: 16px;
  padding: 10px 35px;
  width: 200px;
}

.Widget .bottom-section {
  height: 230px;
}

.Qr {
  text-align: center;
}

@media (min-width: 768px) {
  .Qr {
    text-align: left;
  }
}

.Qr .top-section {
  padding-top: 82px;
  background: #d9e9f6;
  height: 940px;
  position: relative;
}

@media (min-width: 768px) {
  .Qr .top-section {
    height: 560px;
  }
}

@media (min-width: 992px) {
  .Qr .top-section {
    height: 518px;
  }
}

.Qr .top-section .left-section {
  margin: 0 auto;
}

@media (min-width: 992px) {
  .Qr .top-section .left-section {
    margin-top: 41px;
  }
}

.Qr .top-section .left-section h2 {
  text-align: center;
}

@media (min-width: 768px) {
  .Qr .top-section .left-section h2 {
    text-align: left;
  }
}

.Qr .top-section .left-section .description {
  line-height: 1.79;
  margin: 23px 0 0;
}

.Qr .top-section .left-section .subtitle-group {
  background-image: url(/public/images/widget/rectangle.png);
  background-size: contain;
  width: 398px;
  height: 87px;
  display: none;
  position: absolute;
}

@media (min-width: 768px) {
  .Qr .top-section .left-section .subtitle-group {
    display: block;
    top: 57%;
    left: 0;
  }
}

@media (min-width: 768px) {
  .Qr .top-section .left-section .subtitle-group {
    display: block;
    left: inherit;
    top: 92.5%;
  }
}

@media (min-width: 992px) {
  .Qr .top-section .left-section .subtitle-group {
    right: 0;
    top: 62.5%;
  }
}

@media (min-width: 1200px) {
  .Qr .top-section .left-section .subtitle-group {
    top: 51.5%;
  }
}

.Qr .top-section .left-section .subtitle-group .subtitle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 53px 0 33px;
  line-height: 1.28;
  font-size: 1.125rem;
  font-weight: bold;
}

.Qr .top-section .right-section {
  margin: 25px auto 0;
  display: flex;
  justify-content: center;
}

.Qr .top-section .right-section img {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .Qr .top-section .right-section {
    margin: 0 auto;
    justify-content: unset;
  }

  .Qr .top-section .right-section img {
    width: 100%;
    object-fit: contain;
  }
}

.Qr .bottom-section {
  display: none;
}

@media (min-width: 992px) {
  .Qr .bottom-section {
    display: block;
    height: 336px;
  }
}

.Cooperation {
  background: #f1f5fa;
  padding: 35px 0 10px;
}

.Cooperation .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .Cooperation .container {
    justify-content: space-between;
  }
}

.Cooperation .container span {
  padding: 0 15px;
  margin-bottom: 25px;
}

.Connect {
  background: #f9fbfd;
  padding: 30px 0;
}

@media (min-width: 576px) {
  .Connect {
    padding: 56px 0 108px;
  }
}

.Connect .subtitle {
  line-height: 1.79;
  text-align: center;
  max-width: 704px;
  margin: -20px auto 0;
}

.Connect form {
  text-align: center;
  margin-top: 20px;
}

.Connect form .shop-group {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;
}

@media (min-width: 768px) {
  .Connect form .shop-group {
    width: 470px;
    flex-direction: row;
  }
}

.Connect form .shop-group div:first-child {
  width: 100%;
  margin-left: 0;
}

@media (min-width: 768px) {
  .Connect form .shop-group div:first-child {
    width: 62%;
  }
}

.Connect form .shop-group div:last-child {
  width: 100%;
  margin-right: 0;
}

@media (min-width: 768px) {
  .Connect form .shop-group div:last-child {
    width: 36%;
  }
}

.Connect form .external-link {
  width: 80%;
}

@media (min-width: 768px) {
  .Connect form .external-link {
    width: auto;
  }
}

.Connect h2 {
  text-align: center;
  margin-bottom: 40px;
}

.Cabinet {
  padding: 85px 0 95px;
  text-align: center;
  background-image: linear-gradient(174deg, #f9fbfd 68%, #d9e9f6 67%);
  min-height: 575px;
  overflow: hidden;
}

@media (min-width: 576px) {
  .Cabinet {
    text-align: left;
  }
}

.Cabinet .container {
  position: relative;
}

.Cabinet .container .left-section {
  margin: 0 auto;
}

@media (min-width: 992px) {
  .Cabinet .container .left-section {
    margin: 0;
  }
}

.Cabinet .container .left-section .description {
  margin-top: 19px;
  line-height: 1.79;
  max-width: 484px;
}

.Cabinet .container .left-section .btn-row {
  display: flex;
  justify-content: center;
}

.Cabinet .container .left-section .btn-row .connect {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 992px) {
  .Cabinet .container .left-section .btn-row .connect {
    align-items: flex-start;
  }
}

.Cabinet .container .left-section .btn-row .connect a {
  padding: 12px 34px;
  margin-top: 10px;
  min-width: 200px;
  text-align: center;
}

@media (min-width: 992px) {
  .Cabinet .container .left-section .btn-row .connect a {
    padding: 12px 18px;
  }
}

@media (min-width: 1200px) {
  .Cabinet .container .left-section .btn-row .connect a {
    padding: 12px 34px;
  }
}

.Cabinet .container .left-section .btn-row .connect a:last-child {
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, .09);
  margin-right: 10px;
}

.Cabinet .container .left-section .btn-row .connect .tls {
  width: 100%;
  display: flex;
  align-items: end;
  margin-top: 10px;
}

.Cabinet .container .left-section .btn-row .connect .tls .req {
  font-size: 0.75rem;
  font-weight: 600;
  margin-right: 3px;
  font-style: italic;
  color: #2272ff;
}

.Cabinet .container .left-section .btn-row .connect .tls .info {
  font-size: 0.75rem;
  font-weight: 300;
  font-style: italic;
  line-height: 1.33;
  color: #212225;
}

.Cabinet .container .bg-container {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/public/images/cabinet-bg.png);
  display: none;
}

@media (min-width: 992px) {
  .Cabinet .container .bg-container {
    display: block;
    height: 338px;
    width: 504px;
    position: absolute;
    right: -115px;
    top: -15px;
  }
}

@media (min-width: 1200px) {
  .Cabinet .container .bg-container {
    height: 500px;
    width: 806px;
    right: -188px;
    top: -35px;
  }
}

.Cabinet .container .bg-container div {
  min-height: 100%;
}

.Feedback {
  background: #f9fbfd;
  padding: 30px 0;
}

@media (min-width: 576px) {
  .Feedback {
    padding: 56px 0 108px;
  }
}

.Feedback .subtitle {
  line-height: 1.79;
  text-align: center;
  margin: -20px auto 0;
}

.Feedback form {
  text-align: center;
  margin-top: 20px;
}

.Feedback form .shop-group {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;
}

@media (min-width: 768px) {
  .Feedback form .shop-group {
    width: 470px;
    flex-direction: row;
  }
}

.Feedback form .external-link {
  width: 80%;
}

@media (min-width: 768px) {
  .Feedback form .external-link {
    width: auto;
  }
}

.Feedback h2 {
  text-align: center;
  margin-bottom: 40px;
}

.Success {
  display: flex;
  min-height: calc(100vh - 454px);
  background: #f9fbfd;
  padding: 30px 0;
}

@media (min-width: 576px) {
  .Success {
    padding: 56px 0 108px;
  }
}

.Success .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Success .container h2 {
  text-align: center;
  margin-bottom: 40px;
}

.Success .container .subtitle {
  line-height: 1.79;
  text-align: center;
  margin: -20px auto 0;
}

.Success .container a {
  align-self: center;
}

.Navbar {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
  border-bottom: 1px solid #eaeaea;
}

@media (min-width: 992px) {
  .Navbar .container {
    display: flex;
    height: 100%;
    align-items: center;
    max-width: 1360px;
  }
}

.Navbar .container .navbar-header {
  padding: 15px 0 13px;
  display: flex;
  align-content: center;
}

.Navbar .container .navbar-header .navbar-brand {
  height: 40px;
}

.Navbar .container .navbar-header .navbar-toggler {
  margin-left: auto;
  background: none;
  border: none;
  width: 40px;
  position: relative;
  outline: none;
  cursor: pointer;
}

.Navbar .container .navbar-header .navbar-toggler .navbar-toggler-icon {
  display: block;
  width: 70%;
  height: 4px;
  background: #ffe907;
  right: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Navbar .container .navbar-header .navbar-toggler .navbar-toggler-icon:before,
.Navbar .container .navbar-header .navbar-toggler .navbar-toggler-icon:after {
  content: "";
  position: inherit;
  height: inherit;
  background: inherit;
  display: inherit;
  right: 0;
}

.Navbar .container .navbar-header .navbar-toggler .navbar-toggler-icon:before {
  width: 45%;
  top: -10px;
}

.Navbar .container .navbar-header .navbar-toggler .navbar-toggler-icon:after {
  width: 75%;
  top: 10px;
}

@media (min-width: 992px) {
  .Navbar .container .navbar-header .navbar-toggler {
    display: none;
  }
}

.NavbarLinks {
  overflow: hidden;
  transition: 0.3s linear;
  position: relative;

}

.NavbarLinks .nav-item,
.NavbarLinks .nav-item-active {
  padding: 8px 0;
  line-height: 2.29;
  cursor: pointer;
  text-align: left;
}

@media (min-width: 992px) {

  .NavbarLinks .nav-item,
  .NavbarLinks .nav-item-active {
    padding: 12px;
  }
}

.NavbarLinks .nav-item:hover .nav-link,
.NavbarLinks .nav-item:hover .nav-link .cabinet-text,
.NavbarLinks .nav-item-active:hover .nav-link,
.NavbarLinks .nav-item-active:hover .nav-link .cabinet-text {
  color: #fff200;
  transition: all 0.3s linear;
}

.NavbarLinks .nav-item .nav-link,
.NavbarLinks .nav-item-active .nav-link {
  display: flex;
  align-self: center;
  // transition: all 1s ease-out;
}

.NavbarLinks .nav-item .nav-link .cabinet-logo,
.NavbarLinks .nav-item-active .nav-link .cabinet-logo {
  margin-right: 4px;
  height: 20px;
  align-self: center;
}

.NavbarLinks .nav-item .nav-link .cabinet-text,
.NavbarLinks .nav-item-active .nav-link .cabinet-text {
  color: #2273ff;
  font-weight: 600;
  // transition: all 1s ease-out;
}

.NavbarLinks .nav-item-active {
  position: relative;
}

.NavbarLinks .nav-item-active:after {
  content: "";
  position: absolute;
  display: inline-block;
  height: 4px;
  width: 100%;
  background: #2273ff;
  bottom: -5px;
  left: 0;
}

.NavLinksOpen {
  height: 288px;
}

@media (min-width: 768px) {
  .NavLinksOpen {
    height: 380px;
  }
}

.NavLinksClose {
  height: 0;
}

@media (min-width: 768px) {
  .NavLinksClose {
    height: 0;
  }
}

@media (min-width: 992px) {

  .NavLinksOpen,
  .NavLinksClose {
    margin-left: auto;
    display: flex;
    height: auto;
    overflow: visible;
  }
}

.Footer {
  position: relative;
  background: #2b2c31;
  color: #fff;
  padding: 60px 0 22px;
}

.Footer .footer-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .Footer .footer-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.Footer .footer-container .big-logo {
  display: none;
}

@media (min-width: 768px) {
  .Footer .footer-container .big-logo {
    display: block;
  }
}

.Footer .footer-container .small-logo {
  display: block;
  margin: 35px 0;
}

@media (min-width: 768px) {
  .Footer .footer-container .small-logo {
    display: none;
  }
}

.Footer .footer-container .info {
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media (min-width: 768px) {
  .Footer .footer-container .info {
    text-align: right;
  }
}

.Footer .footer-container .info h2 {
  margin-bottom: 15px;
}

.Footer .footer-container .info p,
.Footer .footer-container .info a,
.Footer .footer-container .info address {
  margin: 0;
  line-height: 2.5;
  font-size: 0.8571rem;
  color: #fff;
}

.Footer .footer-container .info .ecommerce {
  text-transform: capitalize;
}

.Footer .copyright {
  text-align: center;
  margin-top: 0;
  line-height: 2.5;
  font-size: 0.8571rem;
}

@media (min-width: 768px) {
  .Footer .copyright {
    margin-top: 75px;
  }
}

.Footer .email {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background: #ffe907;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -25px;
  right: 20px;
  transition: all 0.3s linear;
  outline: none;
}

.Footer .email:hover {
  box-shadow: 6px 6px 10px -4px rgba(0, 0, 0, .7);
}

.Footer .email:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffe907;
  left: 37px;
  bottom: 4px;
}

.Textarea {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 15px;
  text-align: left;
}

@media (min-width: 768px) {
  .Textarea {
    width: 470px;
  }
}

.Textarea .required {
  font-weight: bold;
  color: #ff3c00;
  margin-left: 5px;
}

.Textarea .form-control {
  padding: 12px;
  border-radius: 10px;
  outline: none;
  border: 2px solid #d9d9d9;
  font-weight: bold;
}

.Textarea .form-error {
  margin-top: 5px;
  color: #ff3c00;
}

.Field {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 15px;
  text-align: left;
}

@media (min-width: 768px) {
  .Field {
    width: 470px;
  }
}

.Field .required {
  font-weight: bold;
  color: #ff3c00;
  margin-left: 5px;
}

.Field .form-control {
  padding: 12px;
  border-radius: 10px;
  outline: none;
  border: 2px solid #d9d9d9;
  font-weight: bold;
}

.Field .form-error {
  margin-top: 5px;
  color: #ff3c00;
}

.Title {
  font-weight: bold;
  line-height: 1.28;
  font-size: 2.571rem;
  margin: 0;
  text-align: center;
}

@media (min-width: 576px) {
  .Title {
    text-align: left;
  }
}

.SubTitle {
  font-weight: bold;
  line-height: 1;
  font-size: 2.143rem;
  margin: 0;
}

.Language .language {
  border: none;
  background: #ffe907;
  border-radius: 20.5px;
  outline: none;
  cursor: inherit;
  padding: 4px;
  text-transform: capitalize;
  font-size: 0.8571rem;
  font-weight: bolder;
  width: 64px;
}

.Language .language .language-icon {
  margin-left: 4px;
}

.Language .language-menu {
  transition: all 0.3s linear;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  background: #fff;
  display: flex;
  left: 65px;
  right: 0;
  bottom: 56px;
  height: 28px;
  border-radius: 20.5px;
}

@media (min-width: 768px) {
  .Language .language-menu {
    bottom: 105px;
  }
}

@media (min-width: 992px) {
  .Language .language-menu {
    right: 172px;
    top: 46px;
    display: block;
    left: auto;
    bottom: 8px;
    width: 64px;
    text-align: center;
  }
}

.Language .language-menu .language-list {
  padding: 0 16px;
  transition: all 0.3s linear;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Language .language-menu .language-list:hover {
  background: #ffe907;
}

.language-menu.languageMenuOpen {
  max-width: 92%;
  border: 1px solid #ffe907;
}

@media (min-width: 992px) {
  .language-menu.languageMenuOpen {
    height: 58px;
  }
}

.language-menu.languageMenuClose {
  max-width: 0;
  border: none;
}

@media (min-width: 992px) {
  .language-menu.languageMenuClose {
    height: 0;
  }
}

.Button {
  display: inline-block;
  padding: 12px 76px;
  margin-top: 45px;
  font-weight: 600;
  border-radius: 20.5px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s linear;
}

.Button:hover {
  background: #2273ff;
  color: #fff;
}

.Button.BtnWhite {
  background-color: #fff;
}

.Button.BtnWhite:hover {
  color: #2273ff;
}

.Button.Btn {
  background-color: #ffe907;
}

.upc-modal {
  font-weight: 400;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  width: 90%;
  min-width: 280px;
  max-width: 480px;
  min-height: 100px;
  margin: 50px auto 0;
  padding: 15px;
  transition: 0.3s;
  animation: upc-show-modal 0.5s;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}

.upc-modal * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.upc-modal-wraper {
  position: fixed;
  z-index: 100500;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.3s;
  animation: upc-show 0.4s;
  background: rgba(0, 0, 0, .62);
}

.upc-modal-wraper .close-overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.upc-modal .close {
  font-size: 32px;
  font-weight: 300;
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 4px;
  width: 32px;
  height: 40px;
  cursor: pointer;
  opacity: 1;
  color: #aaa;
  border: none;
}

.upc-modal .proccess {
  display: none;
  text-align: center;
}

.upc-loading .upc-inner {
  display: none;
}

.upc-loading .proccess {
  display: block;
  text-align: center;
}

@keyframes upc-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes upc-show-modal {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.lds-ring {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  margin: 8px;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 8px solid #4b830d;
  border-color: #4b830d transparent transparent transparent;
  border-radius: 50%;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#upc-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.bg-white {
  background: white;
}

.not-found {
  padding: 30px;
  text-align: center;
}

body>div:first-child {
  height: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

body>div>main {
  flex: 1 0 auto;
}

body>div>footer {
  flex: 0 0 auto;
}

.hidden {
  display: none !important;
}

.footer-link img {
  height: 16px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  line-height: 21.5;
}

.footer-link img {
  height: 16px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  line-height: 21.5;
}

.section {
  padding: 30px 0px;
}

@media (min-width: 576px) {
  .section {
    padding: 56px 0px;
  }
}

.subtitle-group {
  line-height: 1.4;
  font-size: 1.143rem;
  color: gray;
}

.text-center {
  text-align: center;
}
