.crossborder{
  display: flex;
  flex-direction: column;
  align-items: center;
  &__footer{
    max-width: 550px;
    margin: 0 auto;
    background: #f6f7f9;
    color: #c3c4c6;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    &-links{
      padding-top: 15px;
      .img-container{
        padding-right: 15px;
        display: inline-block;
      }
      img{
        height: 20px;
        opacity: 0.7;
      }
    }
  }
}
