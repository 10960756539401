.solution{
  padding: 30px 0px;

  &-items{
   margin-top: 72px;
    &__post{
      border-bottom: 1px solid #cecec3;
      padding-bottom: 30px;
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
    }
    &__content{
      max-width: 600px;
      padding: 0px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__img{
      img{
        height: 300px;
        width: 300px;
        object-fit: cover;
      }
    }
    &__title{

    }
    &__description{

    }
  }
}

.inline-flex-table{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  &__column{
    display: flex;
    flex-direction: column;
    padding-right: 10px;
  }
  strong{
    font-size: 1.5em;
    font-weight: bold;
  }
}

@media (min-width: 576px){
  .solution{
    padding: 56px 0px;
  }
}
