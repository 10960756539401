@font-face {
    font-family: OpenSans;
    src: local("Light"), url(/src/assets/fonts/OpenSans-Bold.ttf) format("ttf");
    font-weight: 300;
}
@font-face {
    font-family: OpenSans;
    src: local("Normal"), url(/src/assets/fonts/OpenSans-Regular.ttf) format("ttf");
    font-weight: 400;
}
@font-face {
    font-family: OpenSans;
    src: local("Bolder"), url(/src/assets/fonts/OpenSans-SemiBold.ttf) format("ttf");
    font-weight: 500;
}
@font-face {
    font-family: OpenSans;
    src: local("Bold"), url(/src/assets/fonts/OpenSans-Bold.ttf) format("ttf");
    font-weight: 600;
}
