// modal
.upc-hide-modal {
    .upc-modal {
        opacity: 0;
        transform: translateY(-30%);
    }

    .upc-modal-wraper {
        opacity: 0;
    }
}

.upc-modal {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 100%;
    min-width: 280px;
    width: 480px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    position: relative;
    font-weight: 400;
    min-height: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 15px;
    animation: upc-show-modal .5s;
    transition: .3s;
    z-index: 10;
    display: flex;
    // max-height: calc(100vh - 70px);


    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    &-wraper {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.62);
        transition: .3s;
        animation: upc-show .4s;
        overflow-y: auto;
        z-index: 100500;

        .close-overlay {
            position: fixed;
            left: 0;
            bottom: 0;
            top: 0;
            right: 0;
            z-index: -1;
            cursor: pointer;
            width: 100%;
            height: 100%;
        }

    }

    .upc-close {
        font-weight: 300;
        font-size: 32px;
        height: 32px;
        width: 32px;
        border: none;
        cursor: pointer;
        opacity: 1;
        color: #aaa;
        position: absolute;
        right: 4px;
        top: 0;
        text-align: center;
        z-index: 1000;
    }

    .upc-inner {
        padding-top: 15px;
        width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        max-height: 100%;
    }

    .proccess {
        display: none;
        text-align: center;
    }
}

.upc-loading {
    .upc-inner {
        display: none;
    }

    .proccess {
        text-align: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
}

.upc-noscroll-y {
    overflow-y: hidden;
}

@keyframes upc-show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes upc-show-modal {
    from {
        opacity: 0;
        transform: translateY(-30%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes upc-hide-modal {
    to {
        opacity: 0;
        transform: translateY(-30%);
    }
}


//snipet

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #00c853;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #00c853 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// iframe

#upc-iframe {
    border: none;
    width: 100%;
    height: 100%;
    max-width: 100%;
    flex: 1 0 auto;
}